<template>
  <div class="wrapper">
    <div class="we-login">
      <md-card-media>
        <img src="@/assets/img/logo.png" />
      </md-card-media>
      <div class="form">
        <div v-if="failure" class="error">
          {{ failure }}
        </div>
        <md-field>
          <md-icon>account_circle</md-icon>
          <label>ユーザー名</label>
          <md-input v-model="username"></md-input>
        </md-field>
        <md-field>
          <md-icon>lock</md-icon>
          <label>パスワード</label>
          <md-input v-model="password" type="password" v-on:keyup.enter="onSubmit"></md-input>
        </md-field>
        <div class="forget">
          <a>パスワードを忘れた方はこちら</a>
        </div>
      </div>
      <div class="action">
        <we-button @click="onSubmit">登録</we-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      username: null,
      password: null,
      failure: null,
    }
  },
  methods: {
    ...mapActions('account', ['login']),
    onSubmit() {
      // this will be called only after form is valid. You can do api call here to login
      if (this.username && this.password) {
        this.failure = null;
        const vm = this;
        this.login({username: this.username, password: this.password}).catch(() => {
          vm.failure = "ログイン失敗しました。";
        });
      }
    }
  }
}
</script>